<template>
  <div style="overflow-x: hidden;background-color: white;position: absolute;left: 0;right: 0;top: 0" class="bg">
    <Navigator/>
    <SliderBanner :slider="slider"/>
    <FuncShow :funInfo="funInfo" style="background: #F4F7FF; ">
    </FuncShow>
    <Footer/>
    <ContactFloat/>
  </div>
</template>

<script>
import Navigator from '../widgets/navigator'
import Footer from '../widgets/footer'
import SliderBanner from '../widgets/SliderBanner'
import FuncShow from '../widgets/func-show'
import ContactFloat from '../widgets/contact-float.vue'

export default {
  components: {
    Navigator,
    FuncShow,
    ContactFloat,
    SliderBanner,
    Footer
  },
  data() {
    return {
      slider: {
        image: require('@/assets/images/index/advert/phone.png'),
        title: '骞垮憡浠诲姟',
        desc: "鍚哥矇绁炲櫒浠诲姟澶у巺(骞垮憡浠诲姟銆佹敞鍐屼换鍔°€佹媺鏂颁换鍔°€佽喘鐗╀换鍔°€佸垎浜换鍔＄瓑绛?,鍏ㄥ煙浼樿川骞垮憡濯掍綋璧氶挶"
      },
      funInfo: {
        title: '骞垮憡浠诲姟',
        list: [
          {
            title: '浠诲姟涓績',
            img: require('@/assets/images/index/advert/img2.png'),
            text: '瀹屾垚浠诲姟锛岃禋鏇村绉垎鍜岄噾甯侊紝鎹㈠幓鐜伴噾绾㈠寘'
          },
          {
            title: '鎻掑睆骞垮憡',
            img: require('@/assets/images/index/advert/img3.png'),
            text: '鍏锋湁寮虹儓鐨勮瑙夊啿鍑绘晥鏋?
          },
          {
            title: '寮€灞忓箍鍛?,
            img: require('@/assets/images/index/advert/img4.png'),
            text: '寮€灞忓箍鍛?鍙嚜瀹氫箟娴侀噺鍒嗙粍,瀵圭敤鎴疯繘琛屾墦鏍?绮剧粏鍖栬繍钀ュ疄鐜伴珮娴侀噺浠峰€煎彉鐜?绮惧噯鏅鸿兘鍒嗛厤绛栫暐,鎻愪緵鐪熷疄閫忔槑鍏ㄩ潰鍙樼幇鏈嶅姟,鎸佺画椹卞姩鍙樼幇鏁堢泭鎻愬崌'
          },
          {
            title: '婵€鍔辫棰?,
            img: require('@/assets/images/index/advert/img5.png'),
            text: '婵€鍔辩敤鎴疯鐪嬭棰戯紝棰嗗彇鍙屽€嶅鍔?
          }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
